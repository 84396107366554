<template>
    <section class="page-item-common">
        <div class="page-item-common__back">
            <a class="page-item-common__back-link"
                v-on:click.prevent="back"
                >
                <icon name="caret-left" />
            </a>
        </div>
        <h1 class="page-item-common__title"
            v-text="currentChild.title + ' ' + currentChild.color"
        />
        <div class="page-item-common__clipon"
            v-if="cliponRelatedData"
            >
            Подходит для оправы <a v-bind:href="cliponRelatedData.url" v-text="cliponRelatedData.title + ' ↗\uFE0E'" />
        </div>
        <div class="page-item-common__headline">
            <div class="page-item-common__headline-colors"
                v-if="variants.length > 0"
                >
                <div class="page-item-common__headline-colors-item"
                    v-for="(variant, index) in variants"
                    v-bind:key="index"
                    v-bind:class="{
                        _active: variant.childId === currentChild.id,
                        _bordered: variant.isBorderNeeded,
                        _background_contain: type === 'certificate',
                    }"
                    v-bind:style="{
                        backgroundColor: variant.value,
                        backgroundImage: variant.image ? `url('${variant.image}')` : 'none',
                    }"
                    v-on:click="switchChild(variant.arrayId)"
                />
            </div>
            <div class="page-item-common__headline-price">
                <span class="page-item-common__headline-price-old"
                    v-if="currentChild.price.oldText"
                    v-text="currentChild.price.oldText"
                />
                <span v-text="currentChild.price.text" />
            </div>
        </div>
        <div class="page-item-common__buttons">
            <template v-if="currentChild.availability.is_available_to_buy">
                <div class="page-item-common__buttons-item"
                    v-if="isInCart"
                    >
                    <ui-button
                        v-on:click="showSideblock('cart')"
                        is-uppercase
                        >
                        уже в корзине ✓
                    </ui-button>
                    <div class="page-item-common__buttons-item-plus"
                        v-if="currentChild.isAccessory"
                        >
                        <ui-button
                            v-on:click="addToCart"
                            v-bind:is-disabled="!isAddToCartAvailable"
                            is-uppercase
                            >
                            +1
                        </ui-button>
                    </div>
                </div>
                <div class="page-item-common__buttons-item"
                    v-else
                    >
                    <ui-button
                        v-on:click="addToCart"
                        is-uppercase
                        >
                        Добавить в корзину
                    </ui-button>
                </div>
                <template v-if="isSale">
                    <div class="page-item-common__buttons-item"
                        v-if="!addToCartDisabled"
                        >
                        <ui-button
                            v-on:click="disableAddToCart"
                            variant="gray"
                            is-uppercase
                            >
                            <template v-if="fittingCount === 0">
                                Добавить на примерку +
                            </template>
                            <template v-else-if="isCurrentChildInFittingCart">
                                ✓ Добавлены на примерку ({{ fittingCount }}/4)
                            </template>
                            <template v-else>
                                Добавить на примерку ({{ fittingCount }}/4) +
                            </template>
                        </ui-button>
                    </div>
                    <div class="page-item-common__buttons-disabled"
                        v-else
                        >
                        <div class="page-item-common__buttons-disabled-title">
                            НЕДОСТУПНО ДЛЯ ПРИМЕРКИ
                        </div>
                        <div class="page-item-common__buttons-disabled-text">
                            Эту модель можно примерить только в живых магазинах. Уточните наличие по телефону или в онлайн чате.
                        </div>
                    </div>
                </template>
                <div class="page-item-common__buttons-item"
                    v-else-if="isFittingAvailable && fittingCount < 4"
                    >
                    <ui-button
                        v-on:click="addToFitting"
                        v-bind:variant="isCurrentChildInFittingCart ? 'gray' : 'yellow'"
                        is-uppercase
                        >
                        <template v-if="fittingCount === 0">
                            Добавить на примерку +
                        </template>
                        <template v-else-if="isCurrentChildInFittingCart">
                            ✓ Добавлены на примерку ({{ fittingCount }}/4)
                        </template>
                        <template v-else>
                            Добавить на примерку ({{ fittingCount }}/4) +
                        </template>
                    </ui-button>
                </div>
                <div class="page-item-common__buttons-item _special"
                    v-if="isFittingAvailable && fittingCount >= 4"
                    >
                    <div class="page-item-common__buttons-item-text">
                        Отлично! Вы выбрали для примерки все 4 пары. Мы готовы отправлять курьера :-)
                    </div>
                    <div class="page-item-common__buttons-item-text">
                        <span class="page-item-common__buttons-item-text-trigger"
                            v-on:click="showSideblock('fitting')"
                            >
                            Посмотреть очки
                        </span>
                    </div>
                    <a class="page-item-common__buttons-item-link"
                        v-bind:href="fittingCheckoutHref"
                        >
                        <ui-button variant="yellow"
                            is-uppercase
                            >
                            Оформить примерку
                        </ui-button>
                    </a>
                </div>
            </template>
            <template v-else>
            <div class="page-item-common__buttons-item">
                <ui-button is-uppercase is-disabled disabled>
                    Товар недоступен
                </ui-button>
            </div>
            </template>
        </div>
        <template v-if="type === 'certificate'">
            <div class="page-item-common__text">
                Наши сертификаты бессрочные и действуют во всех магазинах P.Y.E. Если сумма покупки больше номинала, то вы сможете доплатить. А если останется разница — мы выпишем новый сертификат на эту сумму.
                <br />
                <br />
                Если вы заказываете электронный сертификат — мы пришлем на указанную почту письмо. А если вы приобретаете «живой», то мы симпатично его оформим и отправим с курьером. Укажите в комментарии к заказу, какого вида сертификат вам нужен.
            </div>
        </template>
        <template v-else>
            <template v-if="isFittingAvailable">
                <div class="page-item-common__text _uppercase">
                    Выбери 4 пары и примеряй 4 дня ДОМА
                </div>
                <div class="page-item-common__text">
                    <ul class="page-item-common__text-list">
                        <li class="page-item-common__text-list-item"
                            v-text="'Не обязательно покупать'"
                        />
                        <li class="page-item-common__text-list-item"
                            v-text="'Бесплатная доставка в обе стороны'"
                        />
                    </ul>
                </div>
                <div class="page-item-common__text">
                    <span class="page-item-common__text-trigger"
                        v-on:click="showSideblock('delivery')"
                        >
                        Как это работает?
                    </span>
                </div>
            </template>
            <template v-else>
                <div class="page-item-common__text _uppercase"
                    v-if="currentChild.price.value < freeShippingThreshold"
                    >
                    Бесплатная доставка по России для заказов от {{ freeShippingThresholdFormatted }}
                </div>
                <div class="page-item-common__text _uppercase"
                    v-else
                    >
                    Бесплатная доставка по России
                </div>
                <div class="page-item-common__text">
                    <ul class="page-item-common__text-list">
                        <li class="page-item-common__text-list-item"
                            v-text="'Отправляем курьерской службой бесплатно по всей стране'"
                        />
                        <li class="page-item-common__text-list-item"
                            v-text="'Передумали? У вас есть 30 дней на возврат'"
                        />
                    </ul>
                </div>
            </template>
            <div class="page-item-common__spacer" />
            <div class="page-item-common__text">
                ✦ <span class="page-item-common__text-trigger"
                    v-on:click="showSideblock('delivery-old')"
                    >
                    Доставка
                </span>
                <template v-if="currentChild.availability.is_available_to_buy">
                    <br />
                    ✦ <span class="page-item-common__text-trigger"
                        v-on:click="showSideblock('availability')"
                        >
                        Наличие в магазинах
                    </span>
                </template>
            </div>
            <div class="page-item-common__spacer" />
            <template v-if="!item.isCertificate && !item.isAccessory">
                <div class="page-item-common__text _uppercase">
                    ЧТО ВКЛЮЧЕНО
                </div>
                <ul class="page-item-common__include">
                    <li class="page-item-common__include-item"
                        v-if="type === 'clipon'"
                        >
                        В комплекте чехол
                    </li>
                    <li class="page-item-common__include-item"
                        v-else
                        >
                        В комплекте чехол, салфетка и брелок-отвёртка
                    </li>
                    <li class="page-item-common__include-item">
                        <span class="page-item-common__text-trigger"
                            v-on:click="showSideblock('delivery-old')"
                            >
                            Два года гарантии
                        </span>
                    </li>
                </ul>
            </template>
        </template>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'page-item-common',
    inject: {
        givenListData: {
            default: null,
        },
    },
    props: {
        currentChild: {
            type: Object,
        },
        item: {
            type: Object,
        },
        type: {
            type: String,
        },
        lensType: {
            type: String,
        },
        isSale: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        fittingCheckoutHref: config.urls.fitting,
        addToCartDisabled: false,
        freeShippingThreshold: config.cart.freeShippingThreshold,
        freeShippingThresholdFormatted: utils.formats.formatNumerWithSpaces(config.cart.freeShippingThreshold) + ' ₽',
    }),
    computed: {
        ...mapState('cart', [ 'getCartResult' ]),
        isAddToCartAvailable() {
            if (!this.getCartResult) {
                return false;
            }
            return (this.currentChild.availability.num_available - (this.getCartResult.lines.find(x => x.product.id === this.currentChild.id)?.quantity || 0)) > 0;
        },
        cliponRelatedData() {
            if (this.type !== 'clipon' || !this.item.clipon_related_product) {
                return null;
            }
            return this.item.clipon_related_product;
        },
        fittingCount() {
            return this.$store.getters['fitting/count'];
        },
        fittingCart() {
            return this.$store.state.fitting.getCartResult;
        },
        isCurrentChildInFittingCart() {
            if (this.fittingCart === null) {
                return false;
            }
            return this.fittingCart.lines.find(l => l.product.id === this.currentChild.id) !== undefined;
        },
        variants() {
            const validVariants = [];
            this.item.children.forEach((child, index) => {
                if (!child.availability.is_available_to_buy || child.images.length === 0) {
                    return;
                }
                let variant = child.attributes.find(x => {
                    if (this.type === 'certificate') {
                        return x.code === 'Номинал';
                    }
                    if (this.type === 'clipon') {
                        return x.code === 'Цвет оправы';
                    }
                    if (this.type === 'accessory') {
                        return x.code === 'Цвет';
                    }
                    return x.code === 'Цвет оправы';
                });
                let isBorderNeeded = false;
                if (!variant.filter_items || variant.filter_items.length === 0) {
                    return;
                }
                variant = variant.filter_items.find(x => x.show_in_search === false) || variant.filter_items[0];

                if (variant.value === null) {
                    variant.value = '#cdcdcd';
                } else {
                    variant.value = variant.value.substring(variant.value.indexOf('#'));
                    isBorderNeeded = utils.common.isColorLight(variant);
                }

                validVariants.push({
                    arrayId: index,
                    childId: child.id,
                    value: variant.value,
                    image: variant.image,
                    isBorderNeeded,
                });
            });
            return validVariants;
        },
        isFittingAvailable() {
            if (this.type === 'certificate' || this.type === 'accessory') {
                return false;
            }
            return this.item.isFittingAllowed && this.currentChild.isFittingAllowed;
        },
        isInCart() {
            if (!this.getCartResult) {
                return false;
            }
            return this.getCartResult.lines.some(x => x.product.id === this.currentChild.id);
        },
    },
    methods: {
        back() {
            const historyLength = window.history.length;
            if (historyLength > 1 && (
                (document.referrer && document.referrer !== window.location.href) ||
                window.history.previous
            )) {
                window.history.back();
                return;
            }
            if (this.type === 'accessory') {
                window.location.href = config.urls.catalogAccessories;
                return;
            }
            if (this.lensType === 'Оптика') {
                window.location.href = config.urls.catalogOptical;
            } else {
                window.location.href = config.urls.catalog;
            }
        },
        disableAddToCart() {
            this.addToCartDisabled = true;
        },
        addToCart() {
            if (!this.isAddToCartAvailable) {
                return;
            }
            this.showSideblock('cart', { isFirstAutocloseDisabled: true });
            const params = {
                quantity: 1,
                product: this.currentChild,
            };
            if (this.givenListData) {
                params.product.ecommerce.listId = this.givenListData.id;
                params.product.ecommerce.listTitle = this.givenListData.title;
                params.analytics = {
                    listId: this.givenListData.id,
                    listTitle: this.givenListData.title,
                };
            }
            this.$store.dispatch('cart/getCart', {
                onSuccess: () => {
                    this.$store.dispatch('cart/addToCart', params);
                },
            });
        },
        addToFitting() {
            this.showSideblock('fitting');
            if (this.isCurrentChildInFittingCart || this.fittingCount >= 4) {
                return;
            }
            const params = {
                quantity: 1,
                product: this.currentChild,
            };
            this.$store.dispatch('fitting/addToCart', params);
        },
        showSideblock(sideblock, props = {}) {
            if (sideblock === 'availability') {
                this.$store.commit('sideblocks/push', {
                    name: 'availability',
                    props: {
                        item: this.item,
                        currentChild: this.currentChild,
                    },
                });
            } else if (sideblock === 'fitting') {
                this.$store.commit('sideblocks/push', {
                    name: 'fitting',
                });
            } else {
                this.$store.commit('sideblocks/push', {
                    name: sideblock,
                    props,
                });
            }
        },
        switchChild(index) {
            this.$emit('switch-child', index);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-item-common {
    position: sticky;
    top: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    width: var(--infoblock-width);
    max-width: 820px;
    padding: 17px 15px 40px 17px;
    &__back {
        margin-bottom: 28px;
        &-link {
            display: inline-block;
            padding: 10px;
            margin: -10px;

            font-size: 2.4rem;
        }
    }
    &__title {
        .typography-display-sm();

        margin: 0 0 20px;

        font-weight: normal;
        text-transform: uppercase;
    }
    &__clipon {
        margin: -12px 0 20px;
        & > a {
            text-transform: uppercase;
        }
    }
    &__headline {
        width: 100%;
        margin-bottom: 24px;
        &-colors {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            margin: -5px 0;
            &-item {
                position: relative;
                z-index: 1;

                flex: 0 0 auto;
                width: 20px;
                height: 20px;
                margin: 5px 7px;

                background-position: center;
                background-repeat: no-repeat;

                border-radius: 50%;
                cursor: pointer;
                box-shadow:
                    inset 0 0 0 0 fade(@color-gray-darkest, 50%),
                    0 0 0 0 @color-gray-lightest,
                    0 0 0 0 @color-gray-main;

                transition: box-shadow @duration-fast @easing-default;

                &:before {
                    content: '';

                    position: absolute;
                    top: -5px;
                    right: -7px;
                    bottom: -5px;
                    left: -7px;
                    z-index: 1;

                    display: block;
                }
                &:first-child {
                    margin-left: 5px;
                }
                &._bordered {
                    box-shadow:
                        inset 0 0 0 1px fade(@color-gray-darkest, 50%),
                        0 0 0 0 @color-gray-lightest,
                        0 0 0 0 @color-gray-main;
                }
                &._background_contain {
                    background-size: contain;
                }
                &._active,
                &._active:hover {
                    box-shadow:
                        inset 0 0 0 0 fade(@color-gray-darkest, 50%),
                        0 0 0 4px @color-gray-lightest,
                        0 0 0 5px @color-gray-darkest;
                    &._bordered {
                        box-shadow:
                            inset 0 0 0 1px fade(@color-gray-darkest, 50%),
                            0 0 0 4px @color-gray-lightest,
                            0 0 0 5px @color-gray-darkest;
                    }
                }
                &:hover {
                    box-shadow:
                        inset 0 0 0 0 fade(@color-gray-darkest, 50%),
                        0 0 0 4px @color-gray-lightest,
                        0 0 0 5px @color-gray-main;
                    &._bordered {
                        box-shadow:
                            inset 0 0 0 1px fade(@color-gray-darkest, 50%),
                            0 0 0 4px @color-gray-lightest,
                            0 0 0 5px @color-gray-main;
                    }
                }
            }
        }
        &-price {
            .typography-display-sm();

            margin-top: 24px;
            &-old {
                margin-right: 8px;

                text-decoration: line-through;
            }
        }
    }
    &__buttons {
        width: 100%;
        margin-bottom: 24px;
        &-item {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            height: 48px;
            margin-bottom: 6px;
            &:last-child {
                margin-bottom: 0;
            }
            &._special {
                flex-direction: column;
                height: auto;
                padding-top: 8px;
                border: 1px solid @color-gray-darkest;

                text-align: center;
            }
            &-plus {
                margin-left: 1px;
                flex: 0 0 auto;
                min-width: 82px;
            }
            &-text {
                padding: 4px 16px;
                &-trigger {
                    text-decoration: underline;

                    cursor: pointer;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            &-link {
                display: block;
                margin-top: 12px;

                text-decoration: none;
            }
        }
        &-disabled {
            border: 1px solid @color-gray-darkest;

            text-align: center;
            &-title {
                padding: 15px 20px 13px;

                text-transform: uppercase;

                background-color: @color-gray-main;
            }
            &-text {
                padding: 14px 16px 16px;
            }
        }
    }
    &__text {
        .typography-body-md();

        margin-bottom: 8px;
        &._uppercase {
            text-transform: uppercase;
        }
        &-trigger {
            text-decoration: underline;

            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
        }
        &-list {
            list-style: none;
            margin: 0;
            padding: 0;
            &-item {
                padding-left: 16px;
                &::before {
                    content: '— ';
                    margin-left: -16px;
                }
            }
        }
    }
    &__spacer {
        margin-bottom: 40px;
    }
    &__include {
        list-style: none;
        margin: 0;
        padding: 0;
        &-item {
            padding-left: 16px;
            &::before {
                content: '— ';
                margin-left: -16px;
            }
        }
    }
    @media @media-sm-down {
        min-height: 0;
        &__back {
            display: none;
        }
    }
}
</style>
